/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Title } from "components/styled/Title";
import { ProjectsContainer } from "./styled";
import CarFilter from "components/parts/cars/filter";
import ListItem, { IListItem } from "components/parts/news";
import { useEffect, useState } from "react";
import { API } from "services/API/API";
import { ICategory } from "types/category";
import { useCanonicalUrl } from "services/hooks/canonical";
import DocumentMeta from "react-document-meta";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Projects = () => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const location = useLocation();
  const used = location.pathname === "/used";
  const [projects, setProducts] = useState<IListItem[]>([]);
  const [category, setCategory] = useState<ICategory[]>([]);
  const fetchData = (cat: string) => {
    setProducts([])
    if (cat) {
      API.projects
        .getProjectsByCategory(cat, used ? "used-vehicles" : "samodelo-khazi")
        .then((res) => {
          setProducts(
            res?.productsData.data.map((item: any) => {
              return {
                ...item,
                url: "/products/" + item.slug,
                img: item["media"]?.url,
              };
            })
          );
        });
    } else {
      API.projects
        .getProjects(used ? "used-vehicles" : "samodelo-khazi")
        .then((res) => {
          setProducts(
            res?.productsData.data.map((item: any) => {
              return {
                ...item,
                description: item?.category?.title,
                url: "/products/" + item.slug,
                img: item["media"]?.url,
              };
            })
          );
        });
    }
  };

  const getCategories = () => {
    if (!used) {
      API.projects.getCategories("samodelo-khazi").then((res) => {
        setCategory(res.data);
      });
    }
  };

  const url = useCanonicalUrl();
  const meta = {
    title: "კატალოგი | Renault trucks",
    description: "კატალოგი",
    canonical: url,
  };

  useEffect(() => {
    fetchData("");
    getCategories();
  }, [used, lang]);
  return (
    <DocumentMeta {...meta}>
      <ProjectsContainer className="container">
        <div className="text-center mb-600">
          <Title>{used ? "მეორადი ავტომობილები" : t("modelRange")}</Title>
        </div>
        {!used && (
          <CarFilter
            hideSort
            category={category}
            onActive={(e) => fetchData(e)}
            data={["Trending"]}
          />
        )}
        <div className="row">
          {projects.map((item, index) => (
            <div className="col-3" key={`news-${index}`}>
              <ListItem projectsList {...item} description="" />
            </div>
          ))}
        </div>
      </ProjectsContainer>
    </DocumentMeta>
  );
};

export default Projects;
