import {
  FooterBottomMenu,
  FooterContainer,
  FooterCopyRight,
  FooterDescription,
  FooterMenu,
  FooterMenuLink,
} from "components/styled/Footer";
import Fb from "../../assets/icons/fb.svg";
import linkedin from "../../assets/icons/linkedin.svg";
import twitter from "../../assets/icons/twitter.svg";
import youtube from "../../assets/icons/youtube.svg";
import instagram from "../../assets/icons/instagram.svg";
import { useEffect, useState } from "react";
import { API } from "services/API/API";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/logo.svg";
import { useTranslation } from "react-i18next";
import { currentLangUrl } from "helpers";

const Footer = () => {
  const { i18n, t } = useTranslation();
  const lang = i18n.language;
  const [data, setData] = useState({
    address: "",
    phone_1: "",
    twitter_link: "",
    youtube_link: "",
    facebook_link: "",
    linkedin_link: "",
    instagram_link: "",
    email_1: "",
  });
  const short_description =
    "სატვირთო ავტომობილების მწარმოებელი ფრანგული კომპანია, რომელიც მომხმარებლებს კომერციული ტრანსპორტის ფართო არჩევანს სთავაზობს.";
  const engDesc =
    "A French truck manufacturing company that offers customers a wide range of commercial vehicles.";
  const [text, setText] = useState(``);
  const [readMore, setReadMore] = useState(false);

  useEffect(() => {
    API.contact.getContactData().then((res) => {
      setText(res.data.footer_text);
      setData(res.data);
    });
  }, [lang]);

  return (
    <FooterContainer className="pt-500 mt-1000">
      <div className="container">
        <FooterDescription className="mt-500 mb-500 f-p14">
          {readMore ? (
            <span
              dangerouslySetInnerHTML={{
                __html: text,
              }}
            />
          ) : (
            <span
              dangerouslySetInnerHTML={{
                __html: text.slice(0, 300),
              }}
            />
          )}
          <a
            href="/"
            onClick={(e) => {
              e.preventDefault();
              setReadMore(!readMore);
            }}
          >
            {" "}
            {!readMore
              ? i18n.language === "ka"
                ? "...სრულად წაკითხვა"
                : "...Read more"
              : i18n.language === "ka"
                ? "დახურვა"
                : "Close"}
          </a>
        </FooterDescription>

        <FooterMenu className="display-flex pt-500 pb-500 row">
          <FooterMenuLink className="col-4 col-xs-12 col-sm-12 col-md-12 mr-auto">
            <Link to={`${currentLangUrl()}/`}>
              <img alt="renault trucks" src={Logo} />
            </Link>
            <p
              dangerouslySetInnerHTML={{
                __html: lang === "en" ? engDesc : short_description,
              }}
            />
            <div className="display-flex gap-200 mt-100 socials">
              {data.twitter_link && (
                <a target="_blank" href={data.twitter_link} rel="noreferrer">
                  <img src={twitter} alt="" />
                </a>
              )}
              <a target="_blank" href={data.youtube_link} rel="noreferrer">
                <img src={youtube} alt="" />
              </a>
              <a target="_blank" href={data.facebook_link} rel="noreferrer">
                <img src={Fb} alt="" />
              </a>
              <a target="_blank" href={data.linkedin_link} rel="noreferrer">
                <img src={linkedin} alt="" />
              </a>
              <a target="_blank" href={data.instagram_link} rel="noreferrer">
                <img src={instagram} alt="" />
              </a>
            </div>
          </FooterMenuLink>
          <FooterMenuLink className="col-2 col-xs-6 col-sm-6 col-md-4">
            <h4>{t("pages")}</h4>
            <Link to={`${currentLangUrl()}/products`}>{t("modelRange")}</Link>
            <Link to={`${currentLangUrl()}/blog`}>{t("news")}</Link>
            <Link to={`${currentLangUrl()}/offers`}>{t("offers")}</Link>
          </FooterMenuLink>
          <FooterMenuLink className="col-2 col-xs-6 col-sm-6 col-md-4">
            <h4>{t("ourCompany")}</h4>
            <Link to={`${currentLangUrl()}/about`}>{t("aboutUs")}</Link>
          </FooterMenuLink>
          <FooterMenuLink className="col-2 col-xs-6 col-sm-6 col-md-4">
            <h4>{t("contactUs")}</h4>
            <a href={`tel:${data.phone_1}`}>{data.phone_1}</a>
            <a href={`mailto:${data.email_1}`}>{data.email_1}</a>
            <span>{data.address}</span>
          </FooterMenuLink>
        </FooterMenu>

        <FooterBottomMenu className="pt-500 pb-500 gap-300 display-flex justify-center">
          {/* <Link to=`${currentLangUrl()}/static/terms-and-conditions">{t('terms')}</Link> */}
          <Link to={`${currentLangUrl()}/static/privacy-policy`}>{t('privacy')}</Link>
          {/* <Link to="/static/terms-of-use">{t('termsuse')}</Link>
          <Link to="/static/accessibility-statement">{t('accessibility')}</Link>
          <Link to="/static/institutional-equity-and-compliance">{t('institutional')}</Link>
          <Link to="/static/notice-of-nondiscrimination">{t('notice')}</Link> */}
        </FooterBottomMenu>
        <FooterCopyRight className="pb-300 pt-300 text-center">
          Established 1869 · Copyright 2023
        </FooterCopyRight>
      </div>
    </FooterContainer>
  );
};

export default Footer;
