import { globalStyles } from "consts/spaces";
import styled from "styled-components";

export const HomePageContainer = styled.div`
  margin: 0 auto;

  section.grey-bg {
    background: var(--Color-Background-Input_hovered, #f7f8f9);
  }
`;

export const SliderContainer = styled.div`
  margin-top: ${globalStyles.spaces[300]};
  width: 100%;
  position: relative;

  .slider-services {
    position: initial;
    margin-left: -16px;
  }

  .slick-list {
    overflow: visible;
  }

  .slick-slider {
    button {
      top: -58px;
      right: 16px;
      left: auto;
      width: 48px;
      height: 48px;
      padding: 8px;
      transform: rotate(180deg);
      background-size: 24px;
      background-position: center;
      background-repeat: no-repeat;
      z-index: 2;

      &:before {
        display: none;
      }

      &.slick-prev {
        right: 72px;
        transform: rotate(0deg);
      }

      &.slick-next:disabled,
      &.slick-next[disabled],
      &.slick-prev:disabled,
      &.slick-prev[disabled] {
        background: #ffffff00;

        svg path {
          fill: #091e424f;
        }
      }

      &:hover {
        background-color: #f9f9f8;
      }
    }
  }

  &.without-slider {
    .slick-track {
      margin-left: inherit;
    }

    .slick-slider {
      button {
        display: none !important;
      }
    }
  }
`;
