import { globalStyles } from 'consts/spaces'
import styled, { css } from 'styled-components'
import { InputProps } from './Input'

export const StyledContainer = styled.div`
  width: 100%;
  position: relative;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  img {
    position: absolute;
    right: ${globalStyles.spaces[200]};
    top: ${globalStyles.spaces[200]};
  }

  span {
    top: 10px;
    position: absolute;
    left: 10px;
    color: ${(props) => props.theme.colorTextSubtle};
    pointer-events: none;
  }

  .mobile {
    position: absolute;
    left: -30px;
    top: 0;

    &:before {
      content: '';
      position: absolute;
      right: 4px;
      top: 13px;
      height: 24px;
      width: 1px;
      background: ${(props) => props.theme.colorBackgroundNeutralPressed};
    }
  }
`

export const StyledInput = styled.input<InputProps>`
  outline: none;
  width: 100%;
  border-radius: ${globalStyles.radius.inputRadius0};
  border: 1px solid ${(props) => props.theme.colorBorderPrimary};
  background: ${(props) => props.theme.colorBackgroundInput};
  padding: 0 ${globalStyles.spaces[200]};
  height: 54px;
  font-size: ${globalStyles.fonts.p16};
  color: ${(props) => props.theme.colorTextSubtle};

  &::placeholder {
    color: ${(props) => props.theme.colorTextSubtle};
  }

  ${({ transparent }) =>
    transparent &&
    css`
      background-color: transparent;
    `}
  ${({ borderinverse }) =>
    borderinverse &&
    css`
      border-color: #fff;
    `}
  ${({ borderBottom }) =>
    borderBottom &&
    css`
      border-left: 0;
      border-right: 0;
      border-top: 0;
      border-radius: 0;
    `};

  ${({ color }) =>
    color &&
    css`
      color: ${color};

      &::placeholder {
        color: ${color};
        opacity: 1;
      }

      &::-ms-input-placeholder {
        color: ${color};
      }
    `}

  ${({ hasError }) =>
    hasError &&
    css`
      border-bottom: 2px solid ${(props) => props.theme.colorBorderDanger};
      color: ${(props) => props.theme.colorTextDanger};

      &::placeholder {
        color: ${(props) => props.theme.colorTextDanger};
        opacity: 1;
      }

      &::-ms-input-placeholder {
        color: ${(props) => props.theme.colorTextDanger};
      }
    `}

${({ mobile }) =>
    mobile &&
    css`
      padding-left: 106px;
    `}
    
${({ icon }) =>
    icon &&
    css`
      padding-right: 90px;
    `}
`

export const StyledTextarea = styled.textarea<InputProps>`
  outline: none;
  width: 100%;
  border-radius: ${globalStyles.radius.inputRadius0};
  border: 1px solid ${(props) => props.theme.colorBorderPrimary};
  background: ${(props) => props.theme.colorBackgroundInput};
  padding: 12px ${globalStyles.spaces[100]};
  height: 148px;
  font-size: ${globalStyles.fonts.p16};
  color: ${(props) => props.theme.colorTextSubtle};
  // resize: none;

  &::placeholder {
    color: transparent;
    display: none;
  }

  ${({ transparent }) =>
    transparent &&
    css`
      background-color: transparent;
    `}
  ${({ borderinverse }) =>
    borderinverse &&
    css`
      border-color: #fff;
    `}

  ${({ borderBottom }) =>
    borderBottom &&
    css`
      border-left: 0;
      border-right: 0;
      border-top: 0;
      border-radius: 0;
    `};

  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}
`
