import { globalStyles } from "consts/spaces";
import styled from "styled-components";

export const SearchContainer = styled.div`
  h3 {
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    color: ${(props) => props.theme.colorTextPrimary};
    margin: 0;
  }

  h4 {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    color: ${(props) => props.theme.colorTextPrimary};
    margin: 0 0 ${globalStyles.spaces[200]} 0;
  }

  span {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px
    color: ${(props) => props.theme.colorTextPrimary};
  }

  a span,
  a p {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin: 0;
    color: ${(props) => props.theme.colorTextPrimary};
  }

  a img {
    height: 130px;
    width: auto;
    margin-left: auto;
  }

  a {
    margin-top: ${globalStyles.spaces[500]};

    &:hover {
        h4 {
            color: ${(props) => props.theme.colorTextDanger};
        }
    }
  }

  @media (max-width: ${globalStyles.breackPoints.tablet}) {
    h4 {
        font-size: 16px;
        line-height: 24px;
    }

    h3 {
        font-size: 20px;
    }

    a {
        display: block;
        
        img {
            margin: 24px auto 0 auto;
            display: block;
        }
    }
  }
`;
