import { SimilarProjects, SingleProjectContainer } from "./styled";
import { SubTitle } from "components/styled/SubTitle";
import { Button } from "components/styled/Button";
import { useEffect, useState } from "react";
import { API } from "services/API/API";
import { Link, useParams } from "react-router-dom";
import { IProject } from "types/project";
import DocumentMeta from "react-document-meta";
import { useCanonicalUrl } from "services/hooks/canonical";
import OneSlider from "components/parts/oneSlider";
import Request from "components/parts/request";
import { useTranslation } from "react-i18next";
import useDeviceType, { currentLangUrl } from "helpers";
import ListItem, { IListItem } from "components/parts/news";
import { ReactComponent as ArrowLeft } from "assets/icons/CaretLeft.svg";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  EffectFade,
} from "swiper/modules";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import { Slider } from "components/styled/Slider";

interface IProduct {
  product: IProject;
}

export const deviceBreakpoints = {
  mobile: 480,
  tablet: 744,
  md: 1280,
  lg: 1680,
};

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, EffectFade]);

const SingleProject = () => {
  const { t, i18n } = useTranslation();
  const deviceType = useDeviceType();
  const [gallery, setGallery] = useState([
    {
      url: "",
    },
  ]);
  const [offers, setOffers] = useState<IListItem[]>([]);
  const [showNumber, setShowNumber] = useState(false);
  const [request, setRequest] = useState<number>(0);
  const [active, setActive] = useState(false);
  const [data, setData] = useState<IProduct>();
  const [features, setFeatures] = useState<any[]>([]);
  const { id } = useParams();

  const [screenSizes, setScreenSizes] = useState<number>(1);

  useEffect(() => {
    if (id) {
      API.projects.getSingeProject(id, "samodelo-khazi").then((res) => {
        setData(res);
        setGallery(res.product["multiple-images"]);
        setFeatures(res.features);
        API.projects.getSimilarProducts(res.product.id).then((res) => {
          setOffers(
            res.map(
              (i: {
                slug: string;
                url: string;
                img: any;
                media: { url: any };
              }) => {
                i.url = "/products/" + i.slug;
                i.img = i.media.url;
                return i;
              }
            )
          );
        });
      });
    }
  }, [id, i18n.language]);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;

      if (offers.length < 4) {
        setScreenSizes(4);
      } else {
        if (
          screenWidth >= deviceBreakpoints.tablet &&
          screenWidth < deviceBreakpoints.md
        ) {
          setScreenSizes(2.5);
        } else if (
          screenWidth >= deviceBreakpoints.md &&
          screenWidth < deviceBreakpoints.lg
        ) {
          setScreenSizes(3.5);
        } else {
          setScreenSizes(4.5);
        }
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [offers.length]);

  const url = useCanonicalUrl();
  const meta = {
    canonical: url,
  };

  return (
    <DocumentMeta
      {...meta}
      title={
        data?.product.meta_title
          ? `${data?.product.meta_title} | Renault trucks`
          : `${data?.product.title} | Renault trucks`
      }
      description={data?.product.meta_description}
    >
      <SingleProjectContainer className="container">
        <Request open={request} close={() => setRequest(0)} />
        <h1>{data?.product.title}</h1>

        <div className="product-slider mb-600">
          <OneSlider slider={gallery} />
        </div>

        <div className="mb-600 display-flex call-container">
          <div className="button mr-300 display-flex gap-200 align-items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M18.1156 13.678C17.9763 14.7366 17.4564 15.7083 16.6531 16.4116C15.8497 17.1149 14.8177 17.5018 13.75 17.4998C7.54688 17.4998 2.50001 12.453 2.50001 6.24984C2.49809 5.1821 2.88492 4.15017 3.58824 3.34679C4.29155 2.54341 5.26326 2.02352 6.32188 1.88422C6.58958 1.85153 6.86067 1.9063 7.09468 2.04034C7.3287 2.17438 7.51309 2.38052 7.62032 2.62797L9.27032 6.31156V6.32094C9.35242 6.51035 9.38633 6.71715 9.36901 6.92287C9.3517 7.12859 9.2837 7.32681 9.1711 7.49984C9.15704 7.52094 9.14219 7.54047 9.12657 7.56L7.50001 9.48812C8.08516 10.6772 9.32891 11.91 10.5336 12.4967L12.4352 10.8787C12.4538 10.8631 12.4734 10.8484 12.4938 10.835C12.6666 10.7197 12.8655 10.6493 13.0725 10.6302C13.2794 10.6111 13.4878 10.6439 13.6789 10.7256L13.6891 10.7303L17.3695 12.3795C17.6174 12.4864 17.8241 12.6706 17.9585 12.9047C18.093 13.1387 18.1481 13.41 18.1156 13.678Z"
                fill="#1D2125"
              />
            </svg>
            <span>
              {showNumber ? (
                <a href="tel:+0322264444"> +995 32 226 44 44 </a>
              ) : (
                "+995 32 226 4 ***"
              )}
            </span>
            <span
              className="color-red"
              onClick={() => setShowNumber(!showNumber)}
            >
              {t("viewNumber")}
            </span>
          </div>
          <Button
            size="lg"
            variant="danger"
            onClick={() => setRequest(data?.product.id ? data?.product.id : 0)}
          >
            {t("requestACall")}
          </Button>
        </div>

        <div>
          {data?.product.short_description && (
            <p
              dangerouslySetInnerHTML={{
                __html: data?.product.short_description,
              }}
            />
          )}
        </div>

        <div>
          <SubTitle className="left">
            {t("informationAboutTheProduct")}
          </SubTitle>
          <div className="features mt-600 mb-600">
            {features.map((item) => (
              <div key={item.title}>
                <h4>{item?.title}</h4>
                <div className={`features-list ${active ? "active" : ""}`}>
                  {item.data.map(
                    (f: { key: string; value: string; id: number }) =>
                      f.key &&
                      f.value && (
                        <div key={f.id}>
                          <span>{f.key}</span>
                          <span>{f.value}</span>
                        </div>
                      )
                  )}
                </div>
                {item.data.length > 8 && (
                  <Button
                    size="lg"
                    variant="light"
                    className={active ? "activeted" : ""}
                    onClick={() => setActive(!active)}
                  >
                    {!active ? t("seeMore") : t("seeLess")}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M16.692 7.94254L10.442 14.1925C10.384 14.2506 10.3151 14.2967 10.2392 14.3282C10.1633 14.3597 10.082 14.3758 9.99986 14.3758C9.91772 14.3758 9.8364 14.3597 9.76052 14.3282C9.68465 14.2967 9.61572 14.2506 9.55767 14.1925L3.30767 7.94254C3.1904 7.82526 3.12451 7.6662 3.12451 7.50035C3.12451 7.3345 3.1904 7.17544 3.30767 7.05816C3.42495 6.94088 3.58401 6.875 3.74986 6.875C3.91571 6.875 4.07477 6.94088 4.19205 7.05816L9.99986 12.8668L15.8077 7.05816C15.8657 7.00009 15.9347 6.95403 16.0105 6.9226C16.0864 6.89117 16.1677 6.875 16.2499 6.875C16.332 6.875 16.4133 6.89117 16.4892 6.9226C16.565 6.95403 16.634 7.00009 16.692 7.05816C16.7501 7.11623 16.7962 7.18517 16.8276 7.26104C16.859 7.33691 16.8752 7.41823 16.8752 7.50035C16.8752 7.58247 16.859 7.66379 16.8276 7.73966C16.7962 7.81553 16.7501 7.88447 16.692 7.94254Z"
                        fill="#E3021B"
                      />
                    </svg>
                  </Button>
                )}
              </div>
            ))}
          </div>
        </div>

        <SubTitle className="mb-400 left">{t("description")}</SubTitle>
        {data?.product.long_description && (
          <p
            dangerouslySetInnerHTML={{ __html: data?.product.long_description }}
          />
        )}
      </SingleProjectContainer>
      <SimilarProjects className="mt-1000">
        <h2 className="mt-0 mb-300 container">{t("similarProducts")}</h2>
        {deviceType === "mobile" ? (
          <div className="display-flex flex-column gap-300 similar-mobile container">
            {offers.map((item, index) => (
              <div key={`room-${index}`}>
                <Link to={`/products/${item.url}`}>
                  <ListItem showDesc superSmallImg {...item} />
                </Link>
              </div>
            ))}
            <div className="text-center">
              <Link to={`${currentLangUrl()}/products`} className="see-more">
                <span className="align-items-center display-flex gap-50 justify-center">
                  {t("seeMore")}
                  <svg
                    width="19"
                    height="18"
                    viewBox="0 0 19 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="CaretRight">
                      <path
                        id="Vector"
                        d="M13.2733 9.39792L7.64828 15.0229C7.59602 15.0752 7.53398 15.1166 7.46569 15.1449C7.39741 15.1732 7.32422 15.1878 7.25031 15.1878C7.1764 15.1878 7.10322 15.1732 7.03493 15.1449C6.96665 15.1166 6.90461 15.0752 6.85234 15.0229C6.80008 14.9707 6.75863 14.9086 6.73034 14.8403C6.70206 14.772 6.6875 14.6989 6.6875 14.6249C6.6875 14.551 6.70206 14.4779 6.73034 14.4096C6.75863 14.3413 6.80008 14.2792 6.85234 14.227L12.0801 8.99995L6.85234 3.77292C6.7468 3.66737 6.6875 3.52421 6.6875 3.37495C6.6875 3.22568 6.7468 3.08253 6.85234 2.97698C6.95789 2.87143 7.10105 2.81213 7.25031 2.81213C7.39958 2.81213 7.54273 2.87143 7.64828 2.97698L13.2733 8.60198C13.3256 8.65422 13.3671 8.71626 13.3954 8.78454C13.4237 8.85283 13.4383 8.92603 13.4383 8.99995C13.4383 9.07387 13.4237 9.14706 13.3954 9.21535C13.3671 9.28364 13.3256 9.34567 13.2733 9.39792Z"
                        fill="#E3021B"
                      />
                    </g>
                  </svg>
                </span>
              </Link>
            </div>
          </div>
        ) : (
          <Slider>
            <div className="slider-services">
              <div className={`container arrows `}>
                <div className="arrow-left arrow">
                  <ArrowLeft />
                </div>
                <div className="arrow-right arrow">
                  <ArrowLeft />
                </div>
              </div>

              <Swiper
                navigation={{ nextEl: ".arrow-right", prevEl: ".arrow-left" }}
                slidesPerView={screenSizes}
                freeMode={true}
                pagination={{ clickable: true, dynamicBullets: true }}
                initialSlide={0}
              >
                {offers.map((item, index) => (
                  <SwiperSlide className="slider-item" key={`room-${index}`}>
                    <ListItem showDesc superSmallImg {...item} url={item.url} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </Slider>
        )}
      </SimilarProjects>
    </DocumentMeta>
  );
};

export default SingleProject;
