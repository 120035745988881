import { Title } from 'components/styled/Title'
import { OffersContainer } from './styled'
import { useEffect, useState } from 'react'
// import Pagination from 'components/parts/pagination'
import ListItem, { IListItem } from 'components/parts/news'
import { API } from 'services/API/API'
import { dataConvertStandart } from 'helpers'
import { useCanonicalUrl } from 'services/hooks/canonical'
import DocumentMeta from 'react-document-meta'
import { useTranslation } from 'react-i18next'

const Offers = () => {
  const { t, i18n } = useTranslation();
  const [data, setdata] = useState<IListItem[]>([])

  const fetchData = () => {
    API.offers.getOffers().then((res) => {
      setdata(dataConvertStandart(res.data.data.map((item: { url: string; slug: string }) => {
        item.url = '/offers/' + item.slug
        return item
      })))
    })
  }

  useEffect(() => {
    fetchData()
  }, [i18n.language])

  const url = useCanonicalUrl()
  const meta = {
    title: 'შეთავაზებები | Renault trucks',
    description: 'შეთავაზებები',
    canonical: url,
  }

  return (
    <DocumentMeta {...meta}>
      <OffersContainer className="container">
        <div className='text-center mb-600'>
          <Title>{t("offers")}</Title>
        </div>
        <div className="row">
          {data.map((item, index) => (
            <div
              className="col-3 col-xs-12 col-sm-6 col-md-6"
              key={`news-${index}`}
            >
              <ListItem withoutspace smallImg maxDescription {...item} />
            </div>
          ))}
        </div>
        {/* <Pagination count={5} /> */}
      </OffersContainer>
    </DocumentMeta>
  )
}

export default Offers
