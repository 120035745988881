import { IHomeCover } from "types/home";
import { FC } from "react";
import { Parallax } from "react-parallax";
import styled from "styled-components";
import { globalStyles } from "consts/spaces";
import useDeviceType from "helpers";

interface IProps {
  cover: IHomeCover;
}

const ParalaxContainer = styled.div`
  position: relative;

  .overlow {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 2;
    opacity: 0.4;
    height: 100%;
    background-image: linear-gradient(83deg, rgba(0, 0, 0, 0.76) 0%, rgba(0, 0, 0, 0.13) 76.98%);
    background-color: lightgray;
  }

  .react-parallax-bgimage {
    background-position: top center;
    max-height: 100dvw;
  }

  .content {
    height: 550px;
  }

  @media (max-width: ${globalStyles.breackPoints.md}) {
    .content {
      height: 220px;
    }
  }
`;

const HomeBigSection: FC<IProps> = ({ cover }) => {
  const deviceType = useDeviceType();
  return (
    <ParalaxContainer className="paralax-container">
      <div className="overlow" />
      <Parallax
        bgImage={cover.media.url}
        bgImageAlt="the cat"
        strength={deviceType === "mobile" ? -20 : -100}
      >
        <div className="content" />
      </Parallax>
    </ParalaxContainer>
  );
};

export default HomeBigSection;
