/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import DocumentMeta from "react-document-meta";
import { HomePageContainer } from "./styled";
import HomeCars from "components/parts/home/cars";
// import HomeBigSection from "components/parts/home/bigSection";
import HomeBlogs from "components/parts/home/blogs";
import HomeSlider from "components/parts/home/slider";
import { useEffect, useState } from "react";
import { API } from "services/API/API";
import {
  IHomeBlog,
  IHomeCover,
  IHomeProducts,
  // IHomeProject,
  IHomeSlider,
} from "types/home";
import Loader from "components/layout/Loader";
import { useCanonicalUrl } from "services/hooks/canonical";
// import Offers from "components/parts/home/offers";
import { useTranslation } from "react-i18next";
import HomeBigSection from "components/parts/home/bigSection";

const Homepage = () => {
  const [loader, setLoader] = useState(true);
  const [cover, setCover] = useState<IHomeCover>();
  const [blogs, setBlogs] = useState<IHomeBlog[]>([]);
  const [products, setProducts] = useState<IHomeProducts[]>([]);
  // const [offers, setOffers] = useState<IHomeProject[]>([]);
  const [slider, setSliders] = useState<IHomeSlider[]>([]);
  const url = useCanonicalUrl();
  const meta = {
    title: "მთავარი | Renault trucks",
    description: "მთავარი",
    canonical: url,
  };
  const { i18n } = useTranslation();
  const lang = i18n.language;

  useEffect(() => {
    API.home.getHomeData().then((res) => {
      setBlogs(res?.blogs);
      setSliders(res?.sliders);
      setProducts(res?.productCategories);
      setCover(res?.cover[0]);
      // setOffers(
      //   res?.offers.map((item: { [x: string]: { url: string }; img: any }) => {
      //     item.img = item["main-image"]?.url || "";
      //     return item;
      //   })
      // );
      setLoader(false);
    });
  }, [lang]);

  return (
    <DocumentMeta {...meta}>
      {loader && <Loader />}
      <HomePageContainer>
        <section className="mb-1000">
          <HomeSlider slider={slider} />
        </section>
        <div className="container">
          {products && (
            <section className="mb-1000">
              <HomeCars products={products} hidemore scroll />
            </section>
          )}
        </div>
        {/* <section className="grey-bg pt-1000 pb-1000">
          <Offers offers={offers} />
        </section> */}
        {cover && cover.id !== null && (
          <section className="mb-1000">
            <HomeBigSection cover={cover} />
          </section>
        )}
        <div className="container">
          {blogs && blogs.length > 1 && (
            <section className="mb-1000">
              <HomeBlogs blogs={blogs} />
            </section>
          )}
        </div>
      </HomePageContainer>
    </DocumentMeta>
  );
};

export default Homepage;
