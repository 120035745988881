/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from 'components/styled/Button'
import Dropdown from 'components/styled/Dropdown'
import { t } from 'i18next'
import { FC, useEffect, useState } from 'react'
import { FilterCotainer, FilterList } from './styled'
import { ICategory } from 'types/category'
import { useSearchParams } from 'react-router-dom'

interface IProps {
  data?: string[]
  hideSort?: boolean
  category?: ICategory[]
  onActive?: (slug: string) => void
}

const CarFilter: FC<IProps> = ({ data, hideSort, category, onActive }) => {
  const [searchParams] = useSearchParams()
  const [active, setActive] = useState<string>('all')
  const cat = searchParams.get('cat')

  const dropdownItems = [
    { value: 'option1', label: 'Option 1' },
    { value: 'option2', label: 'Option 2' },
    { value: 'option3', label: 'Option 3' },
  ]

  useEffect(() => {
    if (cat) {
      setActive(cat)
      onActive && onActive(cat)
    }
  }, [cat])

  const handleDropdownSelect = (selectedValue: string) => {
    console.log(`Selected: ${selectedValue}`)
  }

  const filter = data ? data : ['Classic', 'SUV', 'Sports', 'Electrified']

  return (
    <>
      <FilterList className="mb-600 mt-600">
        <div className="blog-cats">
          <div className="display-flex gap-100 justify-center">
            <Button
              variant={active === 'all' ? 'base' : 'light'}
              size="lg"
              onClick={() => {
                onActive && onActive('')
                setActive('all')
              }}
            >
              {t('blog.filter.all')}
            </Button>
            {category
              ? category.map((item) => (
                  <Button
                    key={item.id}
                    variant={active === item.slug ? 'base' : 'light'}
                    onClick={() => {
                      onActive && onActive(item.slug)
                      setActive(item.slug)
                    }}
                    size="lg"
                  >
                    {item.title}
                  </Button>
                ))
              : filter.map((item) => (
                  <Button className="mr-100" key={item} variant="base" size="lg">
                    {item}
                  </Button>
                ))}
          </div>
        </div>

        {!hideSort && (
          <FilterCotainer className="display-flex justify-space-between align-items-center justify-space-between hide-mobile mt-300">
            <span className="hide-mobile">Showing 1–12 of 20 results</span>
            <Dropdown
              label={t('blog.filter.sort')}
              items={dropdownItems}
              onSelect={handleDropdownSelect}
            />
          </FilterCotainer>
        )}
      </FilterList>
    </>
  )
}

export default CarFilter
