import styled from "styled-components";

export const CallRequest = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100dvw;
    height: 100dvh;
    z-index: 12;

    .layout {
        opacity: 0.8;
        background: var(--Color-Background-Neutral-Boldest, #1D2125);
        width: 100%;
        height: 100dvh;
    }

    .relative {
        position: relative;

        select {
            position: absolute;
            left: 37px;
            top: 23px;
            z-index: 2;
            font-size: 1rem;
            color: #172B4D;
            border: 0;
            height: 54px;
            background: transparent;
            width: 64px;
            outline: none;

            option {
                font-size: 18px;
            }
        }

        div:before {
            content: '';
            display: inline-block;
            background: rgba(9, 30, 66, 0.31);
            height: 24px;
            width: 1px;
            position: absolute;
            left: 87px;
            top: 15px;
        }

        input {
            padding-left: 100px;
        }
    }

    .modal {
        background: #fff;
        width: 96%;
        max-width: 660px;
        position: absolute;
        top: 30%;
        left: 50%;
        transform: translateX(-50%);

        &-body {
            border-top: 1px solid var(--Color-Border-Primary, rgba(9, 30, 66, 0.14));
            border-bottom: 1px solid var(--Color-Border-Primary, rgba(9, 30, 66, 0.14));
        }

        &-header {
            color: ${(props) => props.theme.colorTextPrimary};
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 28px;
        }
    }
`