import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { Carousel } from 'react-responsive-carousel'
import { Button } from 'components/styled/Button'
import { Slide } from 'components/styled/Slide'
import styled from 'styled-components'
import CarretRightSlider from '../../../assets/icons/CaretRightSlider.svg'
import { globalStyles } from 'consts/spaces'
import { IHomeSlider } from 'types/home'
import { FC } from 'react'
import { removeHtmlTags } from 'helpers'
import { useTranslation } from 'react-i18next'

const CarouselContainer = styled.div`
  .control-arrow.control-prev,
  .control-arrow.control-next {
    right: 3%;
    right: 3%;
    bottom: 45%;
    top: auto;
    z-index: 5;
    width: 3rem;
    height: 3rem;
    background: #fff !important;
    text-align: center;

    @media (max-width: ${globalStyles.breackPoints.tablet}) {
      display: none;
    }

    &:before {
      display: none;
    }

    img {
      width: 30px;
      margin-top: 4px;
    }

    &:hover {
      background: transparent;
    }
  }

  .control-dots {
    & > li.dot {
      width: 12px;
      opacity: 1;
      background-color: ${(props) => props.theme.colorShapeNeutralBolder};
      border-radius: ${globalStyles.radius.cardRadiusCorner100};
      box-shadow: none;

      &.selected {
        width: 32px;
        background-color: ${(props) => props.theme.colorShapeInverse};
      }
    }

    @media (min-width: ${globalStyles.breackPoints.tablet}) {
      display: none;
    }
  }

  .control-arrow.control-prev {
    left: 3%;

    img {
      transform: rotate(-180deg);
    }
  }
`

interface IProps {
  slider: IHomeSlider[]
}

const HomeSlider: FC<IProps> = ({ slider }) => {
  const { t } = useTranslation();

  return (
    <CarouselContainer>
      <Carousel
        swipeable
        emulateTouch
        showArrows
        infiniteLoop={false}
        showStatus={false}
        stopOnHover={false}
        showIndicators={true}
        showThumbs={false}
        renderArrowPrev={(clickHandler, hasPrev) => {
          return (
            <div
              className={`${
                hasPrev ? 'position-absolute' : 'display-none'
              } control-arrow control-prev`}
              onClick={clickHandler}
            >
              <img alt='' src={CarretRightSlider} />
            </div>
          )
        }}
        renderArrowNext={(clickHandler, hasNext) => {
          return (
            <div
              className={`${
                hasNext ? 'position-absolute' : 'display-none'
              } control-arrow control-next`}
              onClick={clickHandler}
            >
              <img alt='' src={CarretRightSlider} />
            </div>
          )
        }}
      >
        {slider.map((item) => (
          <Slide key={item.id}>
            <img src={item.media.url} alt={item.title} />
            <div>
              <div className="container">
                <h2>{item.title}</h2>
                <p className="hide-mobile">
                  {removeHtmlTags(item.description)}
                </p>
                <div>
                  {item.link && (
                    <Button link={item.link} variant="danger" size='lg'>
                      {t('more')}
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </Slide>
        ))}
      </Carousel>
    </CarouselContainer>
  )
}

export default HomeSlider
