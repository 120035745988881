import { about } from './endpoints/about'
import { accessories } from './endpoints/accessories'
import { blogs } from './endpoints/blog'
import { cars } from './endpoints/cars'
import { contact } from './endpoints/contact'
import { home } from './endpoints/home'
import { mopeds } from './endpoints/moped'
import { news } from './endpoints/news'
import { offers } from './endpoints/offers'
import { projects } from './endpoints/projects'

export const API = {
  news: news,
  home: home,
  offers: offers,
  cars: cars,
  mopeds: mopeds,
  accessories: accessories,
  blogs: blogs,
  about: about,
  projects: projects,
  contact: contact,
}
