import { SingleBlogContainer } from "./styled";
import { useEffect, useState } from "react";
import { API } from "services/API/API";
import { useParams } from "react-router-dom";
import { IBlog } from "types/blog";
import { useCanonicalUrl } from "services/hooks/canonical";
import DocumentMeta from "react-document-meta";
import { useTranslation } from "react-i18next";

const SingleBlog = () => {
  const { i18n } = useTranslation();
  const [data, setData] = useState<IBlog>();
  const { id } = useParams();
  useEffect(() => {
    if (id) {
      API.blogs.getBlogDetails(id).then((res) => {
        setData(res.data);
      });
    }
  }, [id, i18n.language]);
  const url = useCanonicalUrl();
  const meta = {
    canonical: url,
  };

  return (
    <DocumentMeta
      {...meta}
      title={
        data?.meta_title
          ? `${data?.meta_title} | Renault trucks`
          : `${data?.title} | Renault trucks`
      }
      description={data?.meta_description}
    >
      <SingleBlogContainer className="container">
        <h1 className="mb-400">{data?.title}</h1>
        {data?.short_description && (
          <p
            className="mb-400"
            dangerouslySetInnerHTML={{ __html: data?.short_description }}
          />
        )}
        <img src={data?.["main-image"].url} alt="Tegeta" />

        {data?.content.map((item) => (
          <p key={item.key}>
            {item.layout === "text" && item.attributes.text !== undefined && item.attributes.text !== null && (
              <p dangerouslySetInnerHTML={{ __html: item.attributes.text.replaceAll('<h1>', '<h2>').replaceAll('</h1>', '</h2>') }} />
            )}

            {item.layout === "image" && item.attributes.image !== undefined && (
              <img src={item.attributes.image} alt={data?.title} />
            )}

            {item.layout === "image_text" &&
              item.attributes.text &&
              item.attributes.image !== undefined && (
                <div className="float-left">
                  <img src={item.attributes.image} alt={data?.title} />
                  <p
                    dangerouslySetInnerHTML={{ __html: item.attributes.text }}
                  />
                </div>
              )}

            {item.layout === "text_image" &&
              item.attributes.text &&
              item.attributes.image !== undefined && (
                <div className="float-right">
                  <img src={item.attributes.image} alt={data?.title} />
                  <p
                    dangerouslySetInnerHTML={{ __html: item.attributes.text }}
                  />
                </div>
              )}
          </p>
        ))}

        {/* <SimilarNews className="mt-1200">
          <h2 className="text-center">მსგავსი სიახლეები</h2>
          <div className="row">
            {similarNews.map((item, index) => (
              <div className="col-6" key={`news-${index}`}>
                <ListItem withoutspace large inside {...item} />
              </div>
            ))}
          </div>
        </SimilarNews> */}
      </SingleBlogContainer>
    </DocumentMeta>
  );
};
export default SingleBlog;
