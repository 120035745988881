import { globalStyles } from 'consts/spaces'
import styled from 'styled-components'

export const FilterCotainer = styled.div``

export const FilterList = styled.div`
  @media (max-width: ${globalStyles.breackPoints.md}) {
    padding-bottom: ${globalStyles.spaces[100]};

    .blog-cats {
      scrollbar-width: none;
      width: 100%;

      @media (max-width: ${globalStyles.breackPoints.md}) {
        overflow: scroll;
  
        & > div {
          display: block;
          min-width: 120%;
          min-width: auto;
          width: max-content;    
  
          button {
            margin-right: ${globalStyles.spaces[200]};
  
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
  }

  button {
    white-space: nowrap;
    font-weight: 700;
    font-size: ${globalStyles.fonts.b20};
    color: ${(props) => props.theme.colorTextPrimary};
  }
`
