/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { BlogList, BlogPageContainer } from "./styled";
import DocumentMeta from "react-document-meta";
import ListItem, { IListItem } from "components/parts/news";
import { useEffect, useState } from "react";
import BlogFilter from "components/parts/news/filter";
import Pagination from "components/parts/pagination";
import { API } from "services/API/API";
import { removeHtmlTags } from "helpers";
import { ICategory } from "types/category";
import { useCanonicalUrl } from "services/hooks/canonical";
import { useTranslation } from "react-i18next";

const Blog = () => {
  const { i18n } = useTranslation();
  const [page, setPage] = useState(1);
  const [cat, setCurrentCat] = useState("");
  const [perPage, setPerPage] = useState(9);
  const [totalPage, setTotalPage] = useState(9);
  const url = useCanonicalUrl();
  const meta = {
    title: "ბლოგი | Renault trucks",
    description: "ბლოგი",
    canonical: url,
  };
  const [category, setCategory] = useState<ICategory[]>([]);
  const [newsData, setNewsData] = useState<IListItem[]>([]);

  const getCategories = () => {
    API.blogs.getCategories().then((res) => {
      setCategory(res.data);
    });
  };

  const fetchData = () => {
    if (cat) {
      API.blogs.getBlogsByCategory(cat, page).then((res) => {
        setPerPage(res.blogsData?.pagination?.per_page);
        setTotalPage(res.blogsData?.pagination?.total);
        setNewsData(
          res?.blogsData.data.map((item: any) => {
            return {
              ...item,
              description: removeHtmlTags(item.short_description),
              url: '/blog/' + item.slug,
              img: item["main-image"]?.url,
            };
          })
        );
      });
    } else {
      API.blogs.getBlogs(page).then((res) => {
        setPerPage(res.blogsData?.pagination?.per_page);
        setTotalPage(res.blogsData?.pagination?.total);
        setNewsData(
          res?.blogsData.data.map((item: any) => {
            return {
              ...item,
              description: removeHtmlTags(item.short_description),
              url: '/blog/' + item.slug,
              img: item["main-image"]?.url,
            };
          })
        );
      });
    }
  };

  useEffect(() => {
    getCategories();
  }, [i18n.language]);

  useEffect(() => {
    fetchData();
  }, [cat, page, i18n.language]);

  const onPageChange = (e: number) => {
    const element = document.getElementById("page-container");
    element?.scrollIntoView({ behavior: "smooth" });
    setPage(e);
  };

  return (
    <DocumentMeta {...meta}>
      <BlogPageContainer className="container" id="page-container">
        <BlogFilter onActive={(e) => setCurrentCat(e)} category={category} />
        <BlogList className="row">
          {newsData.map((item, index) => (
            <div className="col-4 col-md-6 col-xs-12 col-sm-12" key={`news-${index}`}>
              <ListItem blogMax isMore maxDescription withoutspace {...item} />
            </div>
          ))}
        </BlogList>
        {totalPage > perPage && (
          <Pagination
            onChange={(e) => onPageChange(e)}
            page={page}
            perPage={perPage}
            totalPage={totalPage}
          />
        )}
      </BlogPageContainer>
    </DocumentMeta>
  );
};

export default Blog;
