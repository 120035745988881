/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, memo, useState } from 'react'
import { PaginationContainer } from './styled'
import CarretLeft from '../../../assets/icons/CaretLeft.svg'
import CarretRight from '../../../assets/icons/CaretRight.svg'

interface IProps {
  page: number
  perPage: number
  totalPage: number
  onChange: (active: number) => void
}

const Pagination: FC<IProps> = ({ page, perPage, totalPage, onChange }) => {
  const [active, setActive] = useState<number>(page)
  const count = Math.ceil(totalPage / perPage);
  const pages = Array.from(Array( count ), (_, i) => i + 1)

  return (
    <PaginationContainer className="mt-400">
      <a onClick={(e) => {
        e.preventDefault();
        onChange(active - 1)
        setActive(active - 1)
      }} className={active === 1 ? 'invisible' : ''} href="/">
        <img loading="lazy" src={CarretLeft} alt="left" />
      </a>

      {pages.length > 10 ? (
        <>
          {pages.slice(0, 5).map((item) => (
            <a
              className={item === active ? 'active' : ''}
              key={`page-${item}`}
              onClick={(e) => {
                e.preventDefault()
                onChange(item)
                setActive(item)
              }}
              href=""
            >
              {item}
            </a>
          ))}
          <a href="">...</a>
          <a
            className={pages.length === active ? 'active' : ''}
            href=""
            onClick={(e) => {
              e.preventDefault()
              onChange(pages.length)
              setActive(pages.length)
            }}
          >
            {pages.length}
          </a>
        </>
      ) : (
        pages.map((item) => (
          <a
            className={item === active ? 'active' : ''}
            key={`page-${item}`}
            onClick={(e) => {
              e.preventDefault()
              onChange(item)
              setActive(item)
            }}
            href=""
          >
            {item}
          </a>
        ))
      )}

      <a onClick={(e) => {
        e.preventDefault();
        onChange(active + 1)
        setActive(active + 1)
      }} className={active === count ? 'invisible' : ''} href="/">
        <img loading="lazy" src={CarretRight} alt="right" />
      </a>
    </PaginationContainer>
  )
}

export default memo(Pagination)
