import { globalStyles } from 'consts/spaces'
import styled from 'styled-components'

export const ContactContainer = styled.div`
  text-align: center;

  & > p {
    width: 70%;
    max-width: 750px;
    margin: ${globalStyles.spaces[600]} auto;
    font-size: ${globalStyles.fonts.b20};
    color: ${(props) => props.theme.colorTextPrimary};
  }

  & > div {
    margin: ${globalStyles.spaces[600]} auto;
    max-width: 1280px;
    padding: ${globalStyles.spaces[400]} 0;
    border-top: 1px solid ${(props) => props.theme.colorBorderPrimary};

    div {
      min-width: 150px;
    }

    span {
      font-size: ${globalStyles.fonts.p14};
      color: ${(props) => props.theme.colorTextSubtlest};
      margin: 0;
    }

    h5 a,
    h5 {
      margin: 0;
      color: ${(props) => props.theme.colorTextPrimary};
      font-size: ${globalStyles.fonts.p16};
      margin-top: ${globalStyles.spaces[25]};
      font-weight: 400;
    }
  }

  @media (max-width: ${globalStyles.breackPoints.md}) {
    & > div.display-flex {
      flex-direction: column;
      max-width: 100%;
      text-align: center;
      gap: 24px;
      margin: 24px 0;
      padding: 40px 0;
    }

    & > p {
      width: 100%;
      font-size: 14px;
    }
  }
`
