import { globalStyles } from "consts/spaces";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { currentLangUrl } from "helpers";

interface IProps {
  url: string;
  hidemore?: boolean;
}

const StyledContainer = styled(Link)`
  font-size: ${globalStyles.fonts.p16};
  color: ${(props) => props.theme.colorTextDanger};
  display: flex;
  height: 38px;
  align-items: center;
  font-weight: 400;

  svg {
    margin-left: ${globalStyles.spaces[50]};
    height: ${globalStyles.spaces[200]};
  }

  &:hover {
    color: #E12439;
    text-decoration: underline;

    svg path {
      fill: #E12439;
    }
  }
`;

const DiscoverMore: FC<IProps> = ({ url, hidemore }) => {
  const { t } = useTranslation();
  return (
    <StyledContainer className={hidemore ? "hide-mobile" : ""} to={currentLangUrl() + '/' + url}>
      {t("readMore")}
      <svg
        width="19"
        height="18"
        viewBox="0 0 19 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="CaretRight">
          <path
            id="Vector"
            d="M13.2733 9.39792L7.64828 15.0229C7.59602 15.0752 7.53398 15.1166 7.46569 15.1449C7.39741 15.1732 7.32422 15.1878 7.25031 15.1878C7.1764 15.1878 7.10322 15.1732 7.03493 15.1449C6.96665 15.1166 6.90461 15.0752 6.85234 15.0229C6.80008 14.9707 6.75863 14.9086 6.73034 14.8403C6.70206 14.772 6.6875 14.6989 6.6875 14.6249C6.6875 14.551 6.70206 14.4779 6.73034 14.4096C6.75863 14.3413 6.80008 14.2792 6.85234 14.227L12.0801 8.99995L6.85234 3.77292C6.7468 3.66737 6.6875 3.52421 6.6875 3.37495C6.6875 3.22568 6.7468 3.08253 6.85234 2.97698C6.95789 2.87143 7.10105 2.81213 7.25031 2.81213C7.39958 2.81213 7.54273 2.87143 7.64828 2.97698L13.2733 8.60198C13.3256 8.65422 13.3671 8.71626 13.3954 8.78454C13.4237 8.85283 13.4383 8.92603 13.4383 8.99995C13.4383 9.07387 13.4237 9.14706 13.3954 9.21535C13.3671 9.28364 13.3256 9.34567 13.2733 9.39792Z"
            fill="#E3021B"
          />
        </g>
      </svg>
    </StyledContainer>
  );
};

export default DiscoverMore;
