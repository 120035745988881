import { Link, useNavigate } from "react-router-dom";
import { HeaderSearchContainer } from "./styled";
import Logo from "../../../assets/images/logo.svg";
import MobileMenu from "./mobileMenu";
import { FC, useEffect, useState } from "react";
import Input from "components/styled/Input";
import { useTranslation } from "react-i18next";
import CloseIcon from "../../../assets/icons/close.svg";
import { currentLangUrl } from "helpers";

interface IProps {
  dark: boolean;
}

const HeaderSearch: FC<IProps> = ({ dark }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [open, setOpen] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState(false);
  useEffect(() => {
    const handleBodyClass = () => {
      if (open) {
        document.body.classList.add("fixed");
      } else {
        document.body.classList.remove("fixed");
      }
    };

    handleBodyClass();
    return () => {
      document.body.classList.remove("fixed");
    };
  }, [open]);

  const onSubmit = () => {
    if (search) {
      navigate(`${currentLangUrl()}/search?search=${search}`);
      setSearch("");
      setOpen(false);
      setShowSearch(false);
    }
  };

  return (
    <HeaderSearchContainer className="pt-150 pb-150">
      <div className="display-flex gap-150 align-items-center hide-tablet">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="26"
          viewBox="0 0 25 26"
          fill="none"
        >
          <path
            d="M22.6445 17.5975C22.4704 18.9207 21.8205 20.1354 20.8163 21.0145C19.8121 21.8937 18.5222 22.3772 17.1875 22.3748C9.4336 22.3748 3.12501 16.0662 3.12501 8.3123C3.12262 6.97762 3.60615 5.68771 4.4853 4.68349C5.36444 3.67926 6.57908 3.0294 7.90235 2.85527C8.23697 2.81441 8.57584 2.88287 8.86836 3.05043C9.16087 3.21798 9.39136 3.47565 9.5254 3.78496L11.5879 8.38945V8.40117C11.6905 8.63794 11.7329 8.89644 11.7113 9.15359C11.6896 9.41073 11.6046 9.65851 11.4639 9.8748C11.4463 9.90117 11.4277 9.92558 11.4082 9.95L9.37501 12.3602C10.1065 13.8465 11.6611 15.3875 13.167 16.1209L15.544 14.0984C15.5673 14.0788 15.5918 14.0606 15.6172 14.0437C15.8333 13.8996 16.0819 13.8116 16.3406 13.7878C16.5993 13.7639 16.8598 13.8049 17.0986 13.907L17.1113 13.9129L21.7119 15.9744C22.0218 16.108 22.2801 16.3383 22.4482 16.6308C22.6163 16.9234 22.6852 17.2625 22.6445 17.5975Z"
            fill="#44546F"
          />
        </svg>
        <div className="display-flex flex-column">
          <span className="f-p12">{t("contactUs")}:</span>
          <a className="f-p16 color-default" href="tel:+(995)578947823">
            +(995) 32 226 44 44
          </a>
        </div>
      </div>

      <Link className="logo" to={currentLangUrl()}>
        <img src={Logo} alt="logo" />
      </Link>

      <div
        className={`p-100 visible-tablet  burger-menu ${open ? "open" : ""} ${
          dark ? "" : "dark"
        }`}
        onClick={() => setOpen(!open)}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>

      <div className="search">
        <div className={`search-container ${showSearch ? "active" : ""}`}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              onSubmit();
            }}
          >
            <Input
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              borderBottom
              placeholder={`${t("header.search")}...`}
            />
          </form>
          <span onClick={() => setShowSearch(false)}>
            <img
              style={{
                width: "24px",
                position: "relative",
                top: "6px",
              }}
              alt=""
              src={CloseIcon}
            />
          </span>
        </div>
        <svg
          onClick={() => setShowSearch(true)}
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M21.5306 20.4696L16.8365 15.7765C18.1971 14.1431 18.8755 12.048 18.7307 9.92715C18.5859 7.80629 17.629 5.82289 16.0591 4.38956C14.4892 2.95623 12.4271 2.18333 10.3019 2.23163C8.17663 2.27993 6.15181 3.14571 4.64864 4.64888C3.14547 6.15205 2.27968 8.17687 2.23138 10.3021C2.18308 12.4274 2.95599 14.4894 4.38932 16.0593C5.82265 17.6293 7.80604 18.5861 9.92691 18.7309C12.0478 18.8757 14.1428 18.1973 15.7762 16.8368L20.4693 21.5308C20.539 21.6005 20.6218 21.6558 20.7128 21.6935C20.8038 21.7312 20.9014 21.7506 21 21.7506C21.0985 21.7506 21.1961 21.7312 21.2871 21.6935C21.3782 21.6558 21.4609 21.6005 21.5306 21.5308C21.6003 21.4612 21.6556 21.3784 21.6933 21.2874C21.731 21.1963 21.7504 21.0988 21.7504 21.0002C21.7504 20.9017 21.731 20.8041 21.6933 20.713C21.6556 20.622 21.6003 20.5393 21.5306 20.4696ZM3.74997 10.5002C3.74997 9.16519 4.14585 7.86015 4.88755 6.75011C5.62925 5.64008 6.68345 4.77492 7.91686 4.26403C9.15026 3.75314 10.5075 3.61946 11.8168 3.87991C13.1262 4.14036 14.3289 4.78324 15.2729 5.72724C16.2169 6.67125 16.8598 7.87398 17.1203 9.18335C17.3807 10.4927 17.247 11.8499 16.7362 13.0833C16.2253 14.3167 15.3601 15.3709 14.2501 16.1126C13.14 16.8543 11.835 17.2502 10.5 17.2502C8.71037 17.2482 6.99463 16.5364 5.72919 15.271C4.46375 14.0056 3.75195 12.2898 3.74997 10.5002Z"
            fill="#44546F"
          />
        </svg>
      </div>
      <MobileMenu close={() => setOpen(false)} open={open} />
    </HeaderSearchContainer>
  );
};
export default HeaderSearch;
