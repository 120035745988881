import DiscoverMore from 'components/styled/DiscoverMore'
import { SubTitle } from 'components/styled/SubTitle'
import BlogCard from '../blogCard'
import { IHomeBlog } from 'types/home'
import { FC } from 'react'
import { removeHtmlTags } from 'helpers'
import { useTranslation } from 'react-i18next'

interface IProps {
  blogs: IHomeBlog[]
}

const HomeBlogs: FC<IProps> = ({ blogs }) => {
  const { t } = useTranslation();
  const newsData = blogs.map((item) => {
    return {
      img: item.media?.url,
      url: item.slug,
      title: item.title,
      description: removeHtmlTags(item.short_description),
    }
  })
  return (
    <div>
      <SubTitle center>{t("news")}</SubTitle>
      <div className="row">
        <div className="col-6">
          <BlogCard {...newsData[0]} />
        </div>
        <div className="col-6">
          <div className="mb-200">
            <BlogCard isSmall {...newsData[1]} />
          </div>
          <div className="mb-200">
            <BlogCard isSmall {...newsData[2]} />
          </div>
          <div>
            <BlogCard isSmall {...newsData[3]} />
          </div>
        </div>
      </div>
      <div className="display-flex justify-center mt-600">
        <DiscoverMore url={"blog"} />
      </div>
    </div>
  )
}

export default HomeBlogs
