/* eslint-disable no-restricted-globals */
import { SubTitle } from "components/styled/SubTitle";
import { IItem } from "../list";
import { FC, useEffect, useState } from "react";
import { IHomeProducts } from "types/home";
import styled from "styled-components";
import { globalStyles } from "consts/spaces";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { currentLangUrl } from "helpers";

interface IProps {
  title?: string;
  hidemore?: boolean;
  scroll?: boolean;
  products?: IHomeProducts[];
}

const ModelList = styled.div`
  .single-model {
    position: relative;
    width: 100%;
    height: 560px;

    img {
      width: 100%;
      height: 560px;
      object-fit: cover;
    }

    &-overlay {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0.3;
      z-index: 1;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.01) 0%,
        rgba(0, 0, 0, 0.50) 100%
      );
    }

    &-info {
      position: absolute;
      left: 2rem;
      bottom: 2rem;
      color: #fff;
      z-index: 2;

      h3 {
        margin: 0.25rem 0 0 0;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
      }
    }
  }

  @media (max-width: ${globalStyles.breackPoints.md}) {
    .single-model,
    .single-model img {
      height: 320px;
    }

    .single-model-info {
      bottom: 1.5rem;
      left: 1.5rem;
    }
  }
`;

const HomeCars: FC<IProps> = ({ products }) => {
  const { t } = useTranslation();
  const [models, setModels] = useState<IItem[]>([]);

  useEffect(() => {
    if (products && products.length > 0) {
      const arr: IItem[] = products.map((item) => {
        return {
          title: item.title,
          model: item.brand,
          price: item.price || 0,
          img: item?.media?.url,
          url: item.slug,
        };
      });

      setModels(arr);
    }
  }, [products]);

  return (
    <ModelList>
      <SubTitle center>{t("modelRange")}</SubTitle>
      <div className="row gap-300">
        {models.map((item) => (
          <div className="single-model col-3" key={item.title}>
            <Link to={`${currentLangUrl()}/products/?cat=` + item.url}>
              <div className="single-model-overlay" />
              <img src={item.img} alt={item.title} />
              <div className="single-model-info">
                {/* <span>მოდელი</span> */}
                <h3>{item.title}</h3>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </ModelList>
  );
};

export default HomeCars;
