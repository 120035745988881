import { FC } from "react";
import { ListItemContainer } from "./styled";
import Play from "../../../assets/icons/PlayCircle.svg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { currentLangUrl } from "helpers";


function removeHtmlTags(input: string): string {
  // Define a regular expression to match HTML tags
  const htmlRegex = /<[^>]*>/g;
  // Replace all instances of HTML tags with an empty string
  return input.replace(htmlRegex, '');
}

export interface IListItem {
  title: string;
  description: string;
  url: string;
  img: string;
  inside?: boolean;
  smallImg?: boolean;
  withoutspace?: boolean;
  video?: boolean;
  superSmallImg?: boolean;
  large?: boolean;
  videoUrl?: string;
  isMore?: boolean;
  maxDescription?: boolean;
  onOpen?: () => void;
  blogMax?: boolean;
  showDesc?: boolean
  projectsList?: boolean
}

const ListItem: FC<IListItem> = ({
  title,
  url,
  description,
  img,
  inside,
  smallImg,
  withoutspace,
  superSmallImg,
  video,
  onOpen,
  isMore,
  maxDescription,
  large,
  blogMax,
  projectsList,
  showDesc
}) => {
  const { t } = useTranslation();

  return (
    <ListItemContainer
      className={inside ? "inside" : ""}
      withoutspace={!!withoutspace}
    >
      <Link
        onClick={(e) => {
          if (onOpen) {
            onOpen();
            e.preventDefault();
          }
        }}
        to={`${currentLangUrl()}${url}`}
      >
        <img
          className={`${
            smallImg
              ? "smallImg mb-200"
              : superSmallImg
                ? "superSmallImg mb-100"
                : large
                  ? "large"
                  : projectsList ? 'projectsList' : "mb-200 "
          }`}
          src={img}
          alt="blog"
        />

        {video && (
          <div className={`video ${superSmallImg ? "superSmallImg" : ""}`}>
            <img src={Play} alt={title} />
          </div>
        )}

        {inside ? (
          <div className="absolute">
            <p>{description}</p>
            <h3 className="font-weight-700">{title}</h3>
          </div>
        ) : (
          <>
            <h3
              className={`font-weight-700 ${blogMax ? "blogMax" : ""} ${
                superSmallImg ? "superSmallImg" : ""
              }`}
            >
              {title}
            </h3>
            {description && (
              <p
                className={
                  maxDescription
                    ? `maxDescription ${blogMax ? "blogMax" : ""}`
                    : ""
                }
              >
                {showDesc ? <span className="one-line">{removeHtmlTags(description)}</span> : description}
              </p>
            )}
            {isMore && (
              <Link className="main-color" to={`${currentLangUrl()}${url}`}>
                {t('more')}
              </Link>
            )}
          </>
        )}
      </Link>
    </ListItemContainer>
  );
};

export default ListItem;
