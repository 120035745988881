import axiosInstance from '..'

const getCategories = () => {
  return axiosInstance.get('blogs/getcategories').then((res) => {
    return res.data
  })
}

const getBlogs = (page: number) => {
  return axiosInstance.get(`blogs/?page=${page}`).then((res) => {
    return res.data
  })
}

const getBlogsByCategory = (slug: string, page: number) => {
  return axiosInstance.get(`blogs/${slug}/?page=${page}`).then((res) => {
    return res.data
  })
}

const getBlogDetails = (slug: string) => {
  return axiosInstance.get(`blogs/cat/${slug}`).then((res) => {
    return res.data
  })
}

export const blogs = {
  getCategories,
  getBlogs,
  getBlogsByCategory,
  getBlogDetails,
}
