import Input from "components/styled/Input";
import { ContactFormContainer } from "./styled";
import { Button } from "components/styled/Button";
import { useState } from "react";
import { API } from "services/API/API";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";

const ContactForm = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    message: "",
  });

  const changeText = (key: string, value: string) => {
    const formDataCopy = { ...formData };
    // @ts-ignore
    formDataCopy[key] = value;
    setFormData(formDataCopy);
  };

  const send = () => {
    if (!formData.first_name) {
      toast("გთხოვთ შეავსოთ სახელის ველი", { type: "error" });
      return false;
    }

    if (!formData.email) {
      toast("გთხოვთ შეავსოთ ელ-ფოსტა", { type: "error" });
      return false;
    }

    API.contact.send(formData).then((res) => {
      // @ts-ignore
      if (res?.response?.data?.status === false) {
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        for (const [field, messages] of Object.entries(
          // @ts-ignore
          res?.response?.data?.messages
        )) {
          // @ts-ignore
          messages.forEach((message) => {
            toast(message, { type: "error" });
          });
        }
      } else {
        setFormData({
          first_name: "",
          last_name: "",
          email: "",
          phone: "",
          message: "",
        });
        toast("წარმატებით გაიგზავნა", { type: "success" });
      }
    });
  };

  return (
    <ContactFormContainer>
      <ToastContainer />
      <h2 className="m-0">{t('sendMessage')}</h2>
      <div className="display-flex flex-column gap-200 mt-300 mb-300">
        <div className="display-flex gap-200 mobile-column">
          <Input
            onChange={(e) => changeText("first_name", e.target.value)}
            value={formData.first_name}
            transparent
            placeholder={t('name')}
          />
          <Input
            onChange={(e) => changeText("last_name", e.target.value)}
            value={formData.last_name}
            transparent
            placeholder={t('lastname')}
          />
        </div>
        <div className="display-flex gap-200 mobile-column">
          <Input
            onChange={(e) => changeText("email", e.target.value)}
            value={formData.email}
            transparent
            placeholder={t('email')}
          />
          <Input
            onChange={(e) => changeText("phone", e.target.value)}
            value={formData.phone}
            transparent
            placeholder={t('phone')}
            type="number"
          />
        </div>
        <Input
          onChange={(e) => changeText("message", e.target.value)}
          value={formData.message}
          transparent
          placeholder={t('message')}
          type="textarea"
        />
      </div>
      <Button onClick={() => send()} size="lg" variant="danger">
        {t('send')}
      </Button>
    </ContactFormContainer>
  );
};

export default ContactForm;
