import { FC } from 'react'

interface IProps {
  iframeUrl: string
}

const Map: FC<IProps> = ({ iframeUrl }) => {
  return (
    <iframe
      src={iframeUrl}
      width="100%"
      title='map'
      height="400"
      style={{ border: 0, borderRadius: '24px', filter: 'invert(10%)' }}
      allowFullScreen
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
    />
  )
}

export default Map
