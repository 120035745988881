import styled from "styled-components";

export const AboutContainer = styled.div`
    & > div {
        max-width: 100%;

        img {
            max-height: 485px;
        }
    }
`