import axiosInstance from '..'

const getCategories = () => {
  return axiosInstance.get('offers/getcategories').then((res) => {
    return res.data
  })
}

const getOffers = () => {
  return axiosInstance.get('offers/').then((res) => {
    return res.data
  })
}

const getDetails = (slug: string) => {
  return axiosInstance.get('offers/cat/' + slug).then((res) => {
    return res.data
  })
}

const getOffersByCategory = (slug: string) => {
  return axiosInstance.get(`offers/${slug}`).then((res) => {
    return res.data
  })
}

export const offers = {
  getCategories,
  getOffers,
  getDetails,
  getOffersByCategory,
}
