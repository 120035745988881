import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import styled from "styled-components";
import CarretRightSlider from "../../../assets/icons/CaretRightSlider.svg";
import { globalStyles } from "consts/spaces";
import { FC } from "react";
import Fancybox from "../fancybox";
import ZoomIcon from '../../../assets/icons/zoom.svg'

const CarouselContainer = styled.div`
  width: 92%;
  margin: 0 auto;

  .single-slide {
    a {
      display: block;
    }

    img {
      height: 612px;
      width: auto;
      max-width: 95%;
      margin: 0 auto;
      object-fit: cover;
      border-radius: 0;
    }

    .zoom {
      position: absolute;
      width: 48px;
      height: 48px;
      right: 0;
      bottom: 0;
    }
  }

  .control-arrow.control-prev,
  .control-arrow.control-next {
    right: 0;
    bottom: 45%;
    top: auto;
    z-index: 5;
    width: 3rem;
    height: 3rem;
    background: rgba(9, 30, 66, 0.03) !important;
    text-align: center;

    @media (max-width: ${globalStyles.breackPoints.tablet}) {
      display: none;
    }

    &:before {
      display: none;
    }

    img {
      width: 30px;
      margin-top: 4px;
    }

    &:hover {
      background: transparent;
    }
  }

  .control-dots {
    display: none;
  }

  .control-arrow.control-prev {
    left: 0%;

    img {
      transform: rotate(-180deg);
    }
  }

  @media (max-width: ${globalStyles.breackPoints.md}) {
    .single-slide {
      img {
        width: auto;
        height: 300px;
      }
    }

    .control-arrow {
      display: none !important;
    }
  }
`;

interface IGallery {
  url: string;
}

interface IProps {
  slider: IGallery[];
}

const OneSlider: FC<IProps> = ({ slider }) => {
  return (
    <CarouselContainer>
      <Fancybox
        options={{
          Carousel: {
            infinite: true,
          },
        }}
      >
        <Carousel
          swipeable
          emulateTouch
          showArrows
          infiniteLoop={false}
          showStatus={false}
          stopOnHover={false}
          showIndicators={true}
          showThumbs={false}
          renderArrowPrev={(clickHandler, hasPrev) => {
            return (
              <div
                className={`${
                  hasPrev ? "position-absolute" : "display-none"
                } control-arrow control-prev`}
                onClick={clickHandler}
              >
                <img alt="" src={CarretRightSlider} />
              </div>
            );
          }}
          renderArrowNext={(clickHandler, hasNext) => {
            return (
              <div
                className={`${
                  hasNext ? "position-absolute" : "display-none"
                } control-arrow control-next`}
                onClick={clickHandler}
              >
                <img alt="" src={CarretRightSlider} />
              </div>
            );
          }}
        >
          {slider.map((item) => (
            <div className="single-slide" key={item.url}>
              <a data-fancybox="gallery" href={item.url}>
                
                <img src={item.url} alt="" />
                <img className="zoom" alt="" src={ZoomIcon} />
              </a>
            </div>
          ))}
        </Carousel>
      </Fancybox>
    </CarouselContainer>
  );
};

export default OneSlider;
