import { useEffect, useState } from "react";
import { AboutContainer } from "./styled";
import { API } from "services/API/API";
import { SingleBlogContainer } from "pages/Blog/styled";
import DocumentMeta from "react-document-meta";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const About = () => {
  const { i18n } = useTranslation();
  const { id } = useParams();
  const [data, setData] = useState({
    title: "",
    cover: {
      url: "",
    },
    meta_description: "",
    long_description: "",
  });
  useEffect(() => {
    API.about.getAbout(id).then((res) => {
      console.log("res", res);
      setData(res);
    });
  }, [i18n.language, id]);
  return (
    <DocumentMeta
      title={`${data?.title} | Renault trucks`}
      description={data?.meta_description}
    >
    <AboutContainer className="container">
      <SingleBlogContainer>
        <h1 className="text-center">{data?.title}</h1>
        {data?.cover?.url && <img className="mb-200 mb-t-400" src={data?.cover?.url} alt={data?.title}/>}
        <div dangerouslySetInnerHTML={{__html: data.long_description}} />
      </SingleBlogContainer>
    </AboutContainer>
    </DocumentMeta>
  );
};

export default About;
