import React, { FC, InputHTMLAttributes } from 'react'
import Dropdown from './Dropdown'
import { StyledContainer, StyledInput, StyledTextarea } from './styled.input'

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  transparent?: boolean
  borderinverse?: boolean
  borderBottom?: boolean
  color?: string
  icon?: string
  type?: string
  white?: boolean
  hasError?: boolean
  mobile?: boolean
}

const Input: FC<InputProps> = (props) => {
  const dropdownItems = [
    { value: '996', label: '+ 996' },
    { value: '997', label: '+ 997' },
    { value: '998', label: '+ 998' },
  ]

  const handleDropdownSelect = (selectedValue: string) => {
    console.log(`Selected: ${selectedValue}`)
  }

  return (
    <StyledContainer>
      {props.type && props.type === 'textarea' ? (
        <>
          <StyledTextarea {...props} />
          {props.placeholder && !props.value && (
            <span className="placeholder">{props.placeholder}</span>
          )}
        </>
      ) : (
        <StyledInput {...props} />
      )}
      {props.mobile ? (
        <div className="mobile">
          <Dropdown
            label="+ 995"
            items={dropdownItems}
            onSelect={handleDropdownSelect}
          />
        </div>
      ) : null}
      {props.icon ? <img src={props.icon} alt="input-icon" /> : null}
    </StyledContainer>
  )
}

export default Input
