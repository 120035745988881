/* eslint-disable jsx-a11y/anchor-is-valid */
import { useNavigate } from "react-router-dom";
import { MobileMenuContainer } from "./styled";
import { Button } from "components/styled/Button";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { langs } from "locales/languages";
import { changeLanguage } from "i18next";
import { currentLangUrl } from "helpers";

interface IProps {
  open: boolean;
  close: () => void;
}

const MobileMenu: FC<IProps> = ({ open, close }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const redirect = (url: string) => {
    navigate(currentLangUrl() + '/' + url);
    close();
  };

  const [currentLang, setCurrentLang] = useState<string>("ka");
  const changeLang = (lang: string) => {
    setCurrentLang(lang);
    changeLanguage(lang);
  };

  return (
    <MobileMenuContainer className="visible-tablet" open={open}>
      <div className="align-items-flex-start display-flex flex-column gap-400 mt-1200 pl-400 pt-700 pb-300 header-menu-container">
        <Button onClick={() => redirect("/products")} variant="base" size="lg">
          {t("modelRange")}
        </Button>
        <Button onClick={() => redirect("/blog")} variant="base" size="lg">
          {t("news")}
        </Button>
        <Button onClick={() => redirect("/offers")} variant="base" size="lg">
          {t("offers")}
        </Button>
        <Button onClick={() => redirect("/about")} variant="base" size="lg">
          {t("aboutUs")}
        </Button>
        <Button onClick={() => redirect("/contact")} variant="base" size="lg">
          {t("contact")}
        </Button>
      </div>
      <div className="display-flex mt-400 mb-400 gap-400 pl-700">
          {langs.map((item) => (
            <a
              key={item.key}
              className={`${currentLang === item.key ? "color-red" : "color-default"}`}
              onClick={(e) => {
                e.preventDefault();
                changeLang(item.key);
              }}
              href="#"
            >
              {item.name}
            </a>
          ))}
        </div>
    </MobileMenuContainer>
  );
};

export default MobileMenu;
