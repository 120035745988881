import { Button } from "components/styled/Button";
import { useNavigate } from "react-router-dom";
import { HeaderMenuContainer } from "./styled";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { currentLangUrl } from "helpers";

interface IProps {
  onClick: () => void;
  dark: boolean;
  path: string;
  home?: boolean;
}

const HeaderMenu: FC<IProps> = ({ path, home }) => {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  console.log(i18n.language);

  useEffect(() => {
    const storageLang = localStorage.getItem('reno-lang');
    if (storageLang) {
      i18n.changeLanguage(storageLang)
    }
  }, [i18n])

  return (
    <HeaderMenuContainer className={`hide-md ${home ? "is-home" : ""}`}>
      <Button
        className={`${path === "/products" ? "active" : ""}`}
        onClick={() => navigate(currentLangUrl() + "/products")}
        variant="light"
        size="lg"
      >
        {t("modelRange")}
      </Button>
      <Button
        className={`${path === "/blog" ? "active" : ""}`}
        onClick={() => navigate(currentLangUrl() + "/blog")}
        variant="light"
        size="lg"
      >
        {t("news")}
      </Button>
      <Button
        className={`${path === "/offers" ? "active" : ""}`}
        onClick={() => navigate(currentLangUrl() + "/offers")}
        variant="light"
        size="lg"
      >
        {t("offers")}
      </Button>
      <Button
        className={`${path === "/about" ? "active" : ""}`}
        onClick={() => navigate(currentLangUrl() + "/about")}
        variant="light"
        size="lg"
      >
        {t("aboutUs")}
      </Button>
      <Button
        className={`${path === "/contact" ? "active" : ""}`}
        onClick={() => navigate(currentLangUrl() + "/contact")}
        variant="light"
        size="lg"
      >
        {t("contact")}
      </Button>
    </HeaderMenuContainer>
  );
};

export default HeaderMenu;
