import { FC } from "react";
import HeaderTop from "components/parts/header/top";
import { HeaderContainer } from "components/parts/header/styled";
import HeaderSearch from "components/parts/header/search";
import HeaderMenu from "components/parts/header/menu";
import { useLocation } from "react-router-dom";

interface IProps {
  toggleTheme: () => void;
  lang?: string;
  dark: boolean;
  setLang?: (lang: string) => void;
}

const Header: FC<IProps> = ({ toggleTheme, setLang, lang }) => {
  const location = useLocation();
  const home = location.pathname === "/" || location.pathname === "/en";
  return (
    <HeaderContainer className={home ? "pb-0" : `pb-1000`} lang={lang}>
      <HeaderTop dark={true} setLang={setLang} />
      <div className="container">
        <HeaderSearch dark={true}  />
      </div>
      <HeaderMenu home={home} path={location.pathname} dark={true} onClick={toggleTheme} />
    </HeaderContainer>
  );
};

export default Header;
