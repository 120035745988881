import { globalStyles } from "consts/spaces";
import styled from "styled-components";

export const Slider = styled.div`
  .slider-services {
    position: relative;
    z-index: 9;

    .arrows {
      position: absolute;
      height: 48px;
      left: calc(calc(100dvw - 1590px) / 2);
      top: -66px;
      z-index: 3;
      display: flex;
      align-items: center;
      justify-content: end;
      gap: 8px;

      .arrow {
        height: 48px;
        width: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &.arrow-right {
          transform: rotate(180deg);
        }

        &:hover {
          background-color: #dcdfe4;
        }
      }

      @media (max-width: 1870px) {
        left: calc(15dvw / 2);
      }

      @media (max-width: 1438px) {
        left: calc(calc(100dvw - 1128px) / 2);
      }

      @media (max-width: 1278px) {
        left: calc(4dvw / 2);
      }

      @media (max-width: 1023px) {
        left: 0;
        top: -58px;
      }
    }

    @media (max-width: ${globalStyles.breackPoints.tablet}) {
      width: 100dvw;
    }
  }

  .swiper {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;

    @media (min-width: 1024px) {
      padding-left: calc(calc(4dvw - 32px) / 2);
      padding-right: calc(calc(4dvw - 32px) / 2);
    }

    @media (min-width: 1279px) {
      padding-left: calc(calc(100dvw - 1160px) / 2);
      padding-right: calc(calc(100dvw - 1160px) / 2);
    }

    @media (min-width: 1439px) {
      padding-left: calc(calc(15dvw - 32px) / 2);
      padding-right: calc(calc(15dvw - 32px) / 2);
    }

    @media (min-width: 1870px) {
      padding-left: calc(calc(100dvw - 1622px) / 2);
      padding-right: calc(calc(100dvw - 1622px) / 2);
    }
  }

  .swiper-slide:focus-visible {
    outline: none;
  }
`;
