import { FC } from "react";
import { BlogCardContainer } from "./styled";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { currentLangUrl } from "helpers";

interface IProps {
  title: string;
  url: string;
  description: string;
  img: string;
  isSmall?: boolean;
}

const BlogCard: FC<IProps> = ({ title, url, description, img, isSmall }) => {
  const { t } = useTranslation();
  return (
    <Link to={`${currentLangUrl()}/blog/` + url}>
      <BlogCardContainer className={isSmall ? "isSmall" : ""}>
        <div className="img-container">
          <img src={img} alt={title} />
        </div>
        <div>
          <h3>{title}</h3>
          <p>{description}</p>
          <Link to={`${currentLangUrl()}/blog/` + url}>{t("more")}</Link>
        </div>
      </BlogCardContainer>
    </Link>
  );
};

export default BlogCard;
