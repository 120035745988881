import { globalStyles } from 'consts/spaces'
import styled from 'styled-components'

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;

  a {
    margin: ${globalStyles.spaces[50]};
    width: 40px;
    height: 40px;
    background-color: ${(props) => props.theme.colorBackgroundNeutralSubtle};
    color: ${(props) => props.theme.colorTextPrimary};
    display: flex;
    justify-content: center;
    align-items: center;

    &.invisible {
      opacity: 0;
      touch-action: none;
      pointer-events: none;
    }

    &.active,
    &:hover {
      background-color: ${(props) =>
        props.theme.colorBackgroundDangerBold};
      color: ${(props) => props.theme.colorTextInverse};
    }
  }
`
