import { globalStyles } from "consts/spaces";
import styled from "styled-components";

export const HeaderTopContainer = styled.div`
  display: flex;
  justify-content: end;
  height: 40px;
  align-items: center;
  background: var(--Color-Background-Input_hovered, #f7f8f9);

  a {
    padding: ${globalStyles.spaces[0]} ${globalStyles.spaces[150]};
    color: ${(props) => props.theme.colorTextInverse};
    opacity: 0.6;
    border-right: 1px solid ${(props) => props.theme.colorTextDisabled};

    &:last-child {
      border-right: 0;
    }

    &.active {
      pointer-events: none;
    }

    &.active,
    &:hover {
      opacity: 1;
    }

    &.dark {
      color: ${(props) => props.theme.colorTextDisabled};

      &.active,
      &:hover {
        color: ${(props) => props.theme.colorLinkDefault};
      }
    }
  }
`;

export const HeaderContainer = styled.header`
  background-color: transparent;

  @media (max-width: ${globalStyles.breackPoints.mobile}) {
    margin-bottom: ${globalStyles.spaces[400]};
    padding: 0;

    &.pb-0 {
      margin-bottom: 0;
    }

    .search {
      width: 3rem;
      height: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    div .burger-menu {
      border: 12px solid transparent;
      width: 48px;
      height: 44px;
      margin-top: 4px;
    }

    div .search-container {
      top: 0px;
    }
  }

  &.fixed-header {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    width: 100%;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.16) 0%,
      rgba(0, 0, 0, 0) 184.94%
    );
  }

  &.dark:not(.fixed-header) {
    background-color: ${(props) => props.theme.colorBackgroundNeutralBoldest};
  }
`;

export const HeaderSearchContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 101px;
  position: relative;

  a.logo {
    position: absolute;
    left: 50%;
    top: 0.75rem;
    height: 70px;
    transform: translateX(-50%);

    img {
      height: 70px;
    }
  }

  .color-default:hover {
    color: #172B4D;
    text-decoration: underline;
  }

  & > img {
    height: 69px;
  }

  .search {
    position: relative;

    svg {
      cursor: pointer;
    }

    &-container {
      position: absolute;
      width: 0px;
      overflow: hidden;
      top: -12px;
      right: 0;
      display: flex;

      & > span {
        background: #fff;
        font-size: 22px;
        font-weight: 800;
        font-family: system-ui;
        margin-top: 10px;
        cursor: pointer;
        padding: 0 5px;
      }

      &.active {
        width: 220px;
      }
    }
  }

  @media (max-width: ${globalStyles.breackPoints.mobile}) {
    height: auto;
    align-items: top;
  }

  .burger-menu {
    cursor: pointer;
    position: relative;
    z-index: 7;
    width: 24px;
    height: 19px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;

    span {
      display: block;
      position: absolute;
      height: 3px;
      width: 100%;
      background: #44546f;
      border-radius: 9px;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: 0.25s ease-in-out;
      -moz-transition: 0.25s ease-in-out;
      -o-transition: 0.25s ease-in-out;
      transition: 0.25s ease-in-out;
    }

    &.dark span {
      background: #fff;
    }

    span:nth-child(1) {
      top: 0px;
    }

    span:nth-child(2) {
      top: 8px;
    }

    span:nth-child(3) {
      top: 16px;
    }

    &.open span:nth-child(1) {
      top: 18px;
      -webkit-transform: rotate(135deg);
      -moz-transform: rotate(135deg);
      -o-transform: rotate(135deg);
      transform: rotate(135deg);
      top: 9px;
      background: #44546f;
    }

    &.open span:nth-child(2) {
      opacity: 0;
      left: -60px;
      background: #44546f;
    }

    &.open span:nth-child(3) {
      top: 9px;
      background: #44546f;
      -webkit-transform: rotate(-135deg);
      -moz-transform: rotate(-135deg);
      -o-transform: rotate(-135deg);
      transform: rotate(-135deg);
    }
  }

  @media (max-width: ${globalStyles.breackPoints.md}) {
    a.logo img {
      width: auto;
      height: 3rem;
    }
  }
`;

export const HeaderSearchInputContainer = styled.div`
  width: 30%;
`;

interface IProps {
  open: boolean;
}

export const MobileMenuContainer = styled.div<IProps>`
  position: fixed;
  top: 0;
  left: ${(props) => (props.open ? "0px" : "-100%")};
  width: 100vw;
  height: 100vh;
  z-index: 6;
  background-color: #fff;

  div.footer-menu {
    border-top: 1px solid ${(props) => props.theme.colorBorderDisabled};
  }

  div.header-menu {
    border-bottom: 1px solid ${(props) => props.theme.colorBorderDisabled};

    h2 {
      margin: 0;
    }
  }

  .header-menu-container {
    border-top: 1px solid var(--Color-Border-Primary, rgba(9, 30, 66, 0.14));
    border-bottom: 1px solid var(--Color-Border-Primary, rgba(9, 30, 66, 0.14));

    button {
      background: transparent;
    }
  }
`;

export const MenuDropdown = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  border-radius: 0 0 ${globalStyles.radius.cardRadiusCorner100}
    ${globalStyles.radius.cardRadiusCorner100};
  background: ${(props) => props.theme.colorBackgroundSurfaceSurfaceHovered};
  box-shadow: 0px 8px 16px -8px rgba(23, 43, 77, 0.2);
  padding: ${globalStyles.spaces[100]} ${globalStyles.spaces[150]}
    ${globalStyles.spaces[300]} ${globalStyles.spaces[150]};
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  pointer-events: none;
  z-index: 5;

  li {
    min-width: 250px;
    display: block;
    padding: 0 ${globalStyles.spaces[150]};
    text-align: left;
    border-radius: ${globalStyles.radius.cardRadiusCorner50};
    background: ${(props) => props.theme.colorBackgroundSurfaceSurfaceHovered};

    &:hover {
      background: #fff;
    }

    a {
      display: block;
      height: 60px;
      line-height: 60px;
      color: ${(props) => props.theme.colorTextPrimary};
    }
  }
`;

export const HeaderMenuContainer = styled.div`
  display: flex;
  justify-content: center;
  border-bottom: 1px solid var(--Color-Border-Primary, rgba(9, 30, 66, 0.14));

  &.is-home {
    border-bottom: 0;
  }

  button {
    &:hover {
      background-color: ${(props) =>
        props.theme.colorBackgroundSurfaceSurfaceHovered};
      color: ${(props) => props.theme.colorTextPrimary};
    }
  }

  button.position-relative {
    border-radius: 0;

    svg {
      position: relative;
      top: 3px;
      left: 8px;

      path {
        fill: #fff;
      }
    }

    &:hover {
      background: ${(props) =>
        props.theme.colorBackgroundSurfaceSurfaceReversedHovered};
      color: ${(props) => props.theme.colorTextPrimary};

      svg {
        transform: rotate(-180deg);

        path {
          fill: #44546f;
        }
      }

      div {
        opacity: 1;
        pointer-events: all;
        max-height: 300px;
      }
    }
  }
`;
