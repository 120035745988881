import { useEffect, useState } from "react";
import { SearchContainer } from "./styled";
import { Link, useLocation } from "react-router-dom";
import { Title } from "components/styled/Title";
import { API } from "services/API/API";
import { useTranslation } from "react-i18next";
import { currentLangUrl } from "helpers";

interface ISearch {
  count: number;
  data: any[];
}

const Search = () => {
  const { i18n, t } = useTranslation();
  const [products, setProducts] = useState<ISearch>();
  const [offers, setOffers] = useState<ISearch>();
  const [blogs, setBlog] = useState<ISearch>();
  const [search, setSearch] = useState("");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const param = queryParams.get("search");
  useEffect(() => {
    console.log("param", param);
    if (param) {
      setSearch(param);
      API.contact.search(param).then((res) => {
        console.log("res", res);
        setProducts(res.data.products);
        setOffers(res.data.offers);
        setBlog(res.data.blogs);
      });
    }
  }, [param, i18n.language]);

  return (
    <SearchContainer className="container">
      <div className="text-center">
        <Title>ძიების შედეგები "{search}"</Title>
      </div>
      <div className="content">
        {products && products.count > 0 && (
          <div className="mt-800">
            <h3>პროდუქტები</h3>
            <span>ნაპოვნია {products.count} პროდუქტი</span>
            <div>
              {products.data.map((item) => (
                <Link
                  className="display-flex pt-200 pb-200"
                  key={item.title}
                  to={`${currentLangUrl()}/products/${item.slug}`}
                >
                  <div>
                    <h4>{item.title}</h4>
                    <p>{item.brand}</p>
                  </div>
                  <img src={item.media.url} alt={item.title} />
                </Link>
              ))}
            </div>
          </div>
        )}

        {offers && offers.count > 0 && (
          <div className="mt-800">
            <h3>შეთავაზებები</h3>
            <span>ნაპოვნია {offers.count} შეთავაზება</span>
            <div>
              {offers.data.map((item) => (
                <Link
                  className="display-flex"
                  key={item.title}
                  to={`${currentLangUrl()}/offers/${item.slug}`}
                >
                  <div>
                    <h4>{item.title}</h4>
                    <p>{item.short_description}</p>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        )}

        {blogs && blogs.count > 0 && (
          <div className="mt-800">
            <h3>{t('news')}</h3>
            <span>ნაპოვნია {blogs.count} სიახლე</span>
            <div>
              {blogs.data.map((item) => (
                <Link
                  className="display-flex"
                  key={item.title}
                  to={`${currentLangUrl()}/blog/${item.slug}`}
                >
                  <div>
                    <h4>{item.title}</h4>
                    <p>{item.short_description}</p>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        )}
      </div>
    </SearchContainer>
  );
};

export default Search;
