/* eslint-disable @typescript-eslint/no-explicit-any */
// styled.ts
import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${(props) => props.theme.Neutral};
    color: ${(props) => props.theme.colorTextSubtlest};

    .color-red {
      color: ${(props) => props.theme.colorTextDanger};
    }
    
    a {
      color: ${(props) => props.theme.colorTextDanger};

      &:hover {
        color: ${(props) => props.theme.colorLinkBrandedPressed};
      }
    }
  }
`
export default GlobalStyle
